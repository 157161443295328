<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 11:00:55
 * @ Description: 司机管理>C端客户>C端司机列表 
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <div class="table-header-button">
          <template v-for="(btn, i) in pageButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
              {{ btn.text }}
            </ykc-button>
          </template>
        </div>
      </template>
      <ykc-pagination
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
        <AddOrEdit
          v-if="drawerType === 'edit'"
          :active="stepsIndex"
          ref="addOrEdit"
          :isEdit="isEdit"
          :id="id"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import ImportData from './ImportData.vue';
  import { customerUser } from '@/service/apis';
  import { code, offlineExport } from '@/utils';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      ImportData,
    },
    data() {
      return {
        id: null,
        drawerType: '',
        stepsIndex: 0,
        maxStepIndex: 1,
        isEdit: false,
        showDrawer: false,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxStepIndex;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        pageButtons: [
          {
            id: '2',
            text: '新增',
            type: 'plain',
            enabled: () => code('customer:customer:add'),
            handleClick: () => {
              this.add();
            },
          },
          {
            id: '1',
            text: '导出',
            type: 'plain',
            enabled: () => code('customer:customer:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableTitle: '用户分组列表',
        tableColumns: [
          { label: this.$t('账号'), prop: 'userAccount', minWidth: '150px' },
          { label: '姓名', prop: 'nickName', minWidth: '100px' },
          { label: '手机号', prop: 'userPhone', minWidth: '100px' },
          // { label: '会员等级', prop: 'levelName', minWidth: '100px' },
          // { label: '积分', prop: 'points', minWidth: '100px' },
          { label: '余额', prop: 'balance', minWidth: '100px' },
          {
            label: '用户状态',
            prop: 'status',
            scopedSlots: {
              default: ({ row }) => {
                const startStatus = row.status === '1' ? 'status-blue' : 'status-red';
                return (
                  <span class={['userStatus', startStatus]}>
                    {row.status === '1' ? '启用' : '停用'}
                  </span>
                );
              },
            },
          },
        ],
        tableOperateButtons: [],
        pageInfo: {
          current: 0,
          size: 10,
        },
        searchParams: {
          userType: '1', // '1' C端司机 '2'客户司机 '3'子公司司机
          belongToName: '',
          userAccount: '',
          nickName: '',
          userPhone: '',
          mainOrgName: '',
        },
      };
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量导入';
        }
        return `C端用户${this.isEdit ? '编辑' : '新增'}`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '账号',
            placeholder: '请输入账号',
          },
          {
            comName: 'YkcInput',
            key: 'nickName',
            label: '姓名',
            placeholder: '请输入姓名',
          },
          {
            comName: 'YkcInput',
            key: 'userPhone',
            label: '手机号',
            placeholder: '请输入手机号',
          },
        ];
      },
    },
    created() {
      this.searchParams.userAccount = this.$route.query.userAccount;
      this.id = this.$route.query.id;
      this.searchTableList();
      this.initData();

      if (this.id) {
        this.stepsIndex = 0;
        this.maxStepIndex = 1;
        this.isEdit = true;
        this.drawerType = 'edit';
        this.showDrawer = true;
      }
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '1',
            text: '详情',
            enabled: () => code('customer:customer:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/customerCenter/customerCharge/customerDriver/customerDriverDetail',
                query: {
                  id: row.id,
                  type: 'customerCustomer',
                },
              });
            },
          },
          // {
          //   id: '2',
          //   text: '会员',
          //   enabled: () => code('customer:customer:member'),
          //   handleClick: (btn, { row }) => {
          //     this.$router.push({
          //       path: '/operationCenter/vipManage/vipManage/details',
          //       query: {
          //         id: row.id,
          //       },
          //     });
          //   },
          // },
          {
            id: '2',
            text: '财务',
            enabled: () => code('customer:customer:finance'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/financialManagement/accountManagement/cSideDriverAccountsDetail',
                query: {
                  id: row.id,
                },
              });
            },
          },
          {
            enabled: () => code('asset:station:direct:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.stepsIndex = 0;
                  this.id = row.id;
                  this.isEdit = true;
                  this.id = row.id;
                  this.drawerType = 'edit';
                  this.showDrawer = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('customer:customer:stop'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status === '1'}
                onClick={() => {
                  this.changeUserStatus(row);
                }}>
                启用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('customer:customer:stop'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status === '0'}
                onClick={() => {
                  this.changeUserStatus(row);
                }}>
                停用
              </ykc-button>
            ),
          },
        ];
      },
      add() {
        this.stepsIndex = 0;
        this.showAddDrawer = true;
        this.isEdit = false;
        this.id = null;
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          customerUser.queryUserPageList({
            ...this.pageInfo,
            ...this.searchParams,
            userType: '1',
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'c_user',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 删除
       * */
      delRow(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: `是否确认删除？删除之后无法恢复请谨慎操作`,
          onConfirm: done => {
            console.log(done);
            customerUser
              .chargeUserDelete({ id: row.id })
              .then(res => {
                console.log(res);
                done();
                this.$message({
                  message: `删除成功`,
                  type: 'success',
                });
                this.searchTableList();
              })
              .catch(err => {
                console.log(err);
              });
          },
          onCancel: done => {
            console.log(done);
            done();
          },
        });
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      changeUserStatus(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: `是否${row.status === '1' ? '停用' : '启用'}该用户？`,
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            const resultStatus = row.status === '1' ? '0' : '1';
            customerUser.changeUserStatus({ id: row.id, status: resultStatus }).then(res => {
              console.log('', res);
              done();
              this.$message({
                message: `${row.status === '1' ? '停用' : '启用'}成功`,
                type: 'success',
              });
              this.searchTableList();
            });
          },
        });
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);

        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .unUsed {
    margin-left: 20px;
    font-size: 0;
    line-height: 30px;
    color: transparent;

    &::before,
    &::after {
      font-size: 16px;
      color: #0000ff;
    }
  }
  .onUse {
    margin-left: 20px;
    font-size: 0;
    line-height: 30px;
    color: transparent;

    &::before,
    &::after {
      font-size: 16px;
      color: #0000ff;
    }
  }

  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
  .ykc-table {
    .el-table .el-table__row {
      .cell .userStatus {
        padding-left: 8px;
        position: relative;
      }
      .cell .userStatus::after {
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #d0021b;
        position: absolute;
        top: 5.5px;
        left: 0;
      }
      .cell .status-blue::after {
        background: #4d8cfd;
      }
      .cell .status-green::after {
        background: #02c498;
      }
      .cell .status-black::after {
        background: #9b9b9b;
      }
    }
    .sealBtn {
      color: #4d8cfd;
      cursor: pointer;
    }
    .row-item {
      padding: 0px 4px;
      font-size: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 7px;
      display: inline-block;
    }

    .row-item-txt {
      padding: 0 4px;
      min-width: 30px;
      text-align: center;
      display: inline-block;
    }
    .row-green {
      background: linear-gradient(180deg, #07c160 0%, #02e495 100%);
    }
    .row-blue {
      background: linear-gradient(180deg, #1779fe 0%, #72aeff 100%);
    }
  }
</style>
