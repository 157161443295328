<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 11:00:55
 * @ Description: 司机管理>C端客户>C端司机新增/编辑 
 -->
<template>
  <ykc-form class="el-form-wrap">
    <el-steps :active="active" align-center finish-status="success" v-if="!isEdit">
      <el-step title="账号配置" />
      <el-step title="属性设置" />
    </el-steps>
    <div>
      <ykc-form
        :rules="rules"
        :model="ruleForm"
        ref="ruleForm0"
        class="el-form-wrap"
        v-show="active === 0">
        <ykc-form-item
          class="img-error"
          label="用户账号"
          prop="userAccount"
          bottomTip="温馨提示：创建账号初始密码ABCabc123，用户首次登录时需要修改初始密码">
          <ykc-input
            :disabled="isEdit"
            placeholder="请输入用户登录账号（6-16位）"
            minlength="6"
            maxlength="16"
            v-model="ruleForm.userAccount"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="姓名" prop="nickName">
          <ykc-input
            placeholder="请输入用户姓名"
            maxlength="50"
            v-model="ruleForm.nickName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          label="手机号"
          prop="userPhone"
          bottomTip="温馨提示：手机号码可作为短信登录依据，请正确填写">
          <ykc-input
            placeholder="请输入用户手机号"
            maxlength="11"
            v-model="ruleForm.userPhone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="归属地区" prop="citySelect">
          <ykc-cascader
            v-model="citySelect"
            :options="cityData"
            @change="selectChange"></ykc-cascader>
        </ykc-form-item>
      </ykc-form>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-if="active === 1">
        <ykc-form-item label="充电实体卡卡号" prop="chargeCardNo">
          <ykc-input placeholder="请输入充电实体卡卡号" v-model="ruleForm.chargeCardNo"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          label="启充金额"
          prop="startLimitAmount"
          bottomTip="温馨提示：当用户账户金额小于启动金额时无法启动充电">
          <ykc-input placeholder="" maxlength="11" v-model="ruleForm.startLimitAmount"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          label="停充金额"
          prop="stopLimitAmount"
          bottomTip="温馨提示：当用户账户金额小于停充金额时将停止正在充电的订单">
          <ykc-input placeholder="" maxlength="11" v-model="ruleForm.stopLimitAmount"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="一号多充" prop="multipleChargeType">
          <ykc-radio
            :data="[
              { id: '0', name: '关闭' },
              { id: '1', name: '开启' },
            ]"
            @change="handleMultipleChargeType"
            v-model="ruleForm.multipleChargeType"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="车辆绑定" prop="carInfos">
          <div
            style="position: relative"
            class="el-form-item"
            :class="item.error ? 'is-error' : ''"
            v-for="(item, index) in ruleForm.carInfos"
            :key="`plate${index}`">
            <div class="plate-item">
              <div class="plate-item-delete" @click="deleteItem(index)"></div>
              <div style="display: flex; flex-direction: column; width: 98%">
                <ykc-input
                  placeholder="请输入内容"
                  v-model="item.plateNumber"
                  @focus="inputFocus(index)"
                  @change="inputPlate"
                  @blur="checkPlate">
                  <template slot="prepend">车牌号码</template>
                </ykc-input>
              </div>
            </div>
            <div class="el-form-item__error" v-if="item.error">{{ item.error }}</div>
          </div>
          <div class="item-add" @click="addItem"></div>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { loadDicItem } from '@/utils/dictionary';
  import { filterByIdList } from '@/utils/index';
  import {
    customerUser,
    treeUtils,
    customerOrg,
    marketManage,
    customerVehicle,
  } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        userAccount: '',
        cityData: [],
        citySelect: [],
        ruleForm: {
          userType: 1, // C端司机
          belongToId: 1,
          userAccount: '',
          nickName: '',
          userPhone: '',
          provinceId: '',
          districtId: '',
          chargeCardNo: '',
          startLimitAmount: 10,
          stopLimitAmount: 5,
          multipleChargeType: '0',
          carInfos: [],
          carIndex: 0,
        },
        rules: {
          userAccount: [
            { required: true, message: '请输入用户账号', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('用户账号'),
            },
          ],
          nickName: [{ required: true, message: '请输入司机姓名', trigger: 'blur' }],
          userPhone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.cellphoneType.regexp,
              message: regexpObj.regexp.input.cellphoneType.errorTips.error('手机号'),
            },
          ],
          districtId: [{ required: true, message: '请选择归属地区', trigger: 'change' }],
          chargeCardNo: [
            { required: false, message: '请输入充电实体卡卡号', trigger: 'blur' },
            { validator: this.validCard, trigger: 'blur' },
          ],
          startLimitAmount: [
            { required: true, message: '请输入启充金额', trigger: 'change' },
            {
              pattern: regexpObj.regexp.input.decimalNumberType_2.regexp,
              message: regexpObj.regexp.input.decimalNumberType_2.errorTips.error('金额', 99.99),
              trigger: 'blur',
            },
          ],
          stopLimitAmount: [
            { required: true, message: '请输入停充金额', trigger: 'change' },
            {
              pattern: regexpObj.regexp.input.decimalNumberType_2.regexp,
              message: regexpObj.regexp.input.decimalNumberType_2.errorTips.error('金额', 99.99),
              trigger: 'blur',
            },
            {
              validator: (rule, value, callback) => {
                if (value >= this.ruleForm.startLimitAmount) {
                  callback(new Error('停充金额需小于启充金额'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          multipleChargeType: [
            { required: true, message: '请选择是否支持一号多充', trigger: 'blur' },
          ],
          carInfos: [{ validator: this.onlyCheckPlateNumber, trigger: 'blur' }],
        },
        // 详情数据
        detail: {},
        dictionary: {
          vehiclePurpose: [],
          vehiclePropertyRight: [
            { id: '1', name: '个人' },
            { id: '2', name: '机构' },
          ],
        },
        organizations: [],
      };
    },
    created() {
      this.dictionary.vehiclePurpose = loadDicItem('car_purpose');
      // 城市下拉列表/获取树,新增获取
      this.getCityTree();

      // 编辑回显
      if (this.id) {
        customerUser
          .detail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
            if (res.multipleChargeType === '开启') {
              this.ruleForm.multipleChargeType = '1';
            }
            this.ruleForm.carInfos = res.carInfos.filter(item => item != null);
            this.citySelect = [res.provinceId, res.districtId];
            this.userAccount = res.userAccount;
          })
          .catch(() => {});
      }
    },
    computed: {
      /**
       * 车队列表默认展示符
       * */
      organizationPlaceHolder() {
        if (this.organizations.length) {
          return '请输入归属车队';
        }
        return '暂无数据';
      },
      formData() {
        const formData = {
          id: this.id, // 编辑需要传
          belongToId: 1,
          userType: 1,
          userAccount: this.ruleForm.userAccount,
          nickName: this.ruleForm.nickName,
          userPhone: this.ruleForm.userPhone,
          chargeCardNo: this.ruleForm.chargeCardNo,
          provinceId: this.ruleForm.provinceId,
          districtId: this.ruleForm.districtId,
          startLimitAmount: Number(this.ruleForm.startLimitAmount),
          stopLimitAmount: Number(this.ruleForm.stopLimitAmount),
          multipleChargeType: this.ruleForm.multipleChargeType,
          carInfos: this.ruleForm.carInfos.filter(item => item.plateNumber !== ''), // id集合
        };
        return formData;
      },
    },
    methods: {
      filterByIdList,
      /**
       * 切换配置方式
       */
      handleMultipleChargeType(e) {
        this.ruleForm.multipleChargeType = e;
      },
      // 城市选择
      selectChange(e) {
        console.log(JSON.stringify(e));

        // eslint-disable-next-line no-unused-expressions, prefer-destructuring
        this.ruleForm.provinceId = e[0];
        // eslint-disable-next-line prefer-destructuring
        this.ruleForm.districtId = e[1];
      },

      /** 导入电站 */
      stationUpload(file, fileList, isCheck) {
        if (isCheck) {
          customerUser
            .station_upload({ file: file.raw })
            .then(res => {
              // console.log('res---站点导入', res);
              this.formData.configIds = res.stationIds;
            })
            .catch(() => {
              this.$refs.stationUpload.fileList = [];
            });
          this.$refs.ruleForm1.validateField('stationUpload');
        }
      },
      /**
       * 司机名称唯一性校验
       */
      onlyCheckRuleName(rule, value, callback) {
        if (value) {
          customerOrg
            .checkUserAccountRepeat({ userAccount: value })
            .then(res => {
              console.log(JSON.stringify(res));
              if (res && this.ruleForm.userAccount !== this.userAccount) {
                callback(new Error('名称重复'));
              } else {
                callback();
              }
            })
            .catch(e => {
              console.log(JSON.stringify(e));
            });
        }
      },
      /**
       * 实体卡有效性校验
       */
      validCard(rule, value, callback) {
        if (value) {
          marketManage
            .cardCheck({ cardNo: value, userId: this.id ? this.id : null })
            .then(res => {
              console.log(JSON.stringify(res));
              if (!res) {
                callback(new Error('实体卡无效'));
              } else {
                callback();
              }
            })
            .catch(e => {
              console.log(JSON.stringify(e));
              if (e.data.resultCode !== '0') {
                callback(new Error('实体卡无效'));
              }
            });
        } else {
          callback();
        }
      },
      /**
       * 车牌正确性校验
       */
      onlyCheckPlateNumber(rule, value, callback) {
        if (value.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          Promise.all(
            value.map(item => {
              return new Promise(resolve => {
                const { plateNumber } = item;
                if (!plateNumber) {
                  // eslint-disable-next-line
                  return this.$set(item, 'error', '请输入车牌号');
                }
                const len = plateNumber.length;
                // 位数校验
                if (len < 7 && len > 10) {
                  // eslint-disable-next-line
                  return this.$set(item, 'error', '请输入正确的车牌号');
                }
                // 车牌号规则校验
                if (!regexpObj.regexp.input.carLicensePlateNumberType.regexp.test(plateNumber)) {
                  // eslint-disable-next-line
                  return this.$set(item, 'error', '请输入正确的车牌号');
                }
                customerVehicle
                  .checkPlateNumberByMainOrgId({
                    plateNumber,
                    mainOrgId: 1,
                    userId: this.id ? this.id : null,
                  })
                  .then(res => {
                    console.log(JSON.stringify(res));
                    if (res === '1') {
                      return this.$set(item, 'error', `车牌${plateNumber}重复`);
                    }
                    resolve();
                    return this.$delete(item, 'error');
                  })
                  .catch(e => {
                    console.log(JSON.stringify(e));
                  });
                // eslint-disable-next-line
                return false;
              });
            })
          ).then(() => callback());
        } else {
          callback();
        }
      },
      /**
       * 清除文件
       */
      handleRemove(propName) {
        this.ruleForm[propName] = [];
      },
      getCityTree() {
        treeUtils.getDistrict({ level: '3' }).then(res => {
          this.cityData = res.districts;
        });
      },
      inputFocus(index) {
        console.log('inputFocus', JSON.stringify(index));
        this.carIndex = index;
      },
      inputPlate(e) {
        console.log(JSON.stringify(e));
        if (this.ruleForm.carInfos && this.ruleForm.carInfos[this.carIndex]) {
          this.ruleForm.carInfos[this.carIndex].plateNumber = e;
        }
      },
      checkPlate() {
        this.$refs.ruleForm1.validateField('carInfos', Error => {
          if (!Error) {
            console.log(1111);
          }
        });
      },
      propertyRightChange(e) {
        console.log(JSON.stringify(e));
        this.ruleForm.carInfos[this.carIndex].propertyRight = e;
      },
      purposeChange(e) {
        console.log(JSON.stringify(e));
        this.ruleForm.carInfos[this.carIndex].purpose = e;
      },
      addItem() {
        if (!this.ruleForm.carInfos) {
          this.ruleForm.carInfos = [];
        }
        this.ruleForm.carInfos.push({
          carType: '1',
          purpose: '1',
          propertyRight: '1',
          plateNumber: '',
        });
      },
      deleteItem(item) {
        console.log(item);

        this.ruleForm.carInfos.splice(item, 1);
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              customerUser
                .add(this.formData)
                .then(res => {
                  console.log('新增', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              customerUser
                .edit(this.formData)
                .then(res => {
                  console.log('编辑', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .plate-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-delete {
      cursor: pointer;
      text-align: center;
      margin-left: 20px;
      margin-right: 20px;
      height: 22px;
      width: 22px;

      background: no-repeat url('~@/assets/images/icon-delete.png');
      background-size: 20px 20px;
      background-origin: content-box;
      position: relative;
    }
  }
  .item {
    position: relative;
    &-add {
      cursor: pointer;
      text-align: center;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      height: 20px;
      width: 20px;
      background: no-repeat url('~@/assets/images/icon-add.png');
      background-size: 20px 20px;
      background-origin: content-box;
      position: relative;
      &::before {
        content: ' ';
        position: absolute;
        left: -20px;
        top: 2px;
        height: 16px;
      }
      &::after {
        content: '添加车辆';
        position: absolute;
        left: 10px;
        top: 0px;
        height: 16px;
        width: 100px;
      }
    }
  }
</style>
